import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Tile from '../../components/Tile/Tile';
import Alert from '../../components/Alert/Alert';
import ToolTip from '../../components/ToolTip/ToolTip';

import OBDlogo from '../../svgs/OBDlogo.svg';
import MobilePhone from '../../svgs/mobilePhone.svg';
import Discussion from '../../svgs/discussion.svg';

const WorkingWithYourBuilderPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			homeImg: file(relativePath: { eq: "inConstructionHouse.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			buildersImg: file(relativePath: { eq: "builders_closeUp02.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			view='buyer'
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
			header={
				<>
					<span style={{ fontWeight: `bold` }}>
						<FormattedMessage id='builderExpectations.headerAi' />
					</span>{' '}
					<FormattedMessage id='builderExpectations.headerAii' />
				</>
			}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.expectations' })} />
			<Container>
				<Row className='pageRow'>
					<Col>
						<h2>
							<span className='thin'>
								<FormattedMessage id='builderExpectations.headerBi' />
							</span>{' '}
							<FormattedMessage id='builderExpectations.headerBii' />
						</h2>
						<Alert type='warning' header={<FormattedMessage id='commonHdrs.note' />}>
							<p>
								<FormattedMessage id='builderExpectations.contentA' />{' '}
								<a
									href='../../../HCRA_IllegalBuildingComplaintForm_2022_08_11_v07.pdf'
									aria-label={intl.formatMessage({
										id: 'builderExpectations.contentB'
									})}
									download
								>
									<FormattedMessage id='builderExpectations.contentB' />
								</a>{' '}
								<FormattedMessage id='builderExpectations.contentC' />{' '}
								<a href='mailto:investigations@hcraontario.ca'>investigations@hcraontario.ca</a>.
							</p>
						</Alert>
						<p>
							<FormattedMessage id='builderExpectations.contentD' />
							<br />
							<FormattedMessage id='builderExpectations.contentE' />
							<br />
							<ToolTip
								link='/glossary/#illegalBuilding'
								internal
								definition={<FormattedMessage id='glossaryTerms.illegalDef' />}
							>
								<FormattedMessage id='glossaryTerms.illegalTerm' />
							</ToolTip>{' '}
							<FormattedMessage id='builderExpectations.contentF' />
						</p>
					</Col>
				</Row>

				<Row align='stretch' className='pageRow'>
					<Col md={12}>
						<h3 class='sm'>
							<FormattedMessage id='builderExpectations.headerC' />
						</h3>
					</Col>
					<Col md={4}>
						<Tile icon={<OBDlogo />}>
							<h4>
								<FormattedMessage id='builderExpectations.headerD' />
							</h4>
							<p>
								<FormattedMessage id='builderExpectations.contentG' />{' '}
								<a
									href='https://obd.hcraontario.ca'
									aria-label={intl.formatMessage({
										id: 'builderExpectations.contentH'
									})}
								>
									<FormattedMessage id='builderExpectations.contentH' />
								</a>{' '}
								<FormattedMessage id='builderExpectations.contentI' />
							</p>
						</Tile>
					</Col>
					<Col md={4}>
						<Tile icon={<MobilePhone />}>
							<h4>
								<FormattedMessage id='builderExpectations.headerE' />
							</h4>
							<p>
								<FormattedMessage id='builderExpectations.contentJ' />
							</p>
						</Tile>
					</Col>
					<Col md={4}>
						<Tile icon={<Discussion />}>
							<h4>
								<FormattedMessage id='builderExpectations.headerF' />
							</h4>
							<p>
								<FormattedMessage id='builderExpectations.contentK' />
							</p>
						</Tile>
					</Col>
				</Row>

				<Row className='pageRow' align='center'>
					<Col md={6}>
						<Img fluid={data.homeImg.childImageSharp.fluid} alt='' />
					</Col>

					<Col md={6}>
						<h3 className='sm'>
							<FormattedMessage id='builderExpectations.headerG' />
						</h3>
						<ol className='largeNums'>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.headerH' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentL' />
									<br />
									<FormattedMessage id='builderExpectations.contentLi' />{' '}
									<Link to='/builder-vendor/competency-requirements/'>
										<FormattedMessage id='builderExpectations.contentLii' />
									</Link>
									<FormattedMessage id='builderExpectations.contentLiii' />
								</div>
							</li>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.headerI' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentM' />{' '}
									<a
										href={intl.formatMessage({
											id: 'builderExpectations.link01'
										})}
										aria-label={intl.formatMessage({
											id: 'builderExpectations.contentMi'
										})}
									>
										<FormattedMessage id='builderExpectations.contentMi' />
									</a>{' '}
									<FormattedMessage id='builderExpectations.contentMii' />{' '}
									<a
										href={intl.formatMessage({
											id: 'builderExpectations.link02'
										})}
										aria-label={intl.formatMessage({
											id: 'builderExpectations.contentMiii'
										})}
									>
										<FormattedMessage id='builderExpectations.contentMiii' />
									</a>
									. <FormattedMessage id='builderExpectations.contentMiv' />
								</div>
							</li>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.headerJ' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentN' />
								</div>
							</li>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.headerK' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentO' />
								</div>
							</li>
						</ol>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h3 className='sm'>
							<FormattedMessage id='builderExpectations.headerL' />
						</h3>
						<ol className='largeNums'>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.contentP_B' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentP' />
									<ul>
										<li>
											<FormattedMessage id='builderExpectations.list01Ai' />{' '}
											<a
												href='https://obd.hcraontario.ca'
												aria-label={intl.formatMessage({
													id: 'builderExpectations.contentH'
												})}
											>
												<FormattedMessage id='builderExpectations.contentH' />
											</a>{' '}
											<FormattedMessage id='builderExpectations.list01Aii' />
										</li>
										<li>
											<FormattedMessage id='builderExpectations.list01B' />
										</li>
										<li>
											<FormattedMessage id='builderExpectations.list01C' />
										</li>
									</ul>
								</div>
							</li>
							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.contentQ_B' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentQ' />{' '}
									<a href='mailto:complaints@hcraontario.ca'>complaints@hcraontario.ca</a>.
								</div>
							</li>

							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.contentR_B' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentRi' />{' '}
									<a href='https://www.tarion.com/'>Tarion</a>{' '}
									<FormattedMessage id='builderExpectations.contentRii' />
								</div>
							</li>

							<li>
								<div>
									<b>
										<FormattedMessage id='builderExpectations.contentS_B' />
									</b>
									<br />
									<FormattedMessage id='builderExpectations.contentSi' />{' '}
									<ToolTip
										link='/glossary/#preDeliveryInspection'
										internal
										definition={<FormattedMessage id='glossaryTerms.pdiDef' />}
									>
										<FormattedMessage id='glossaryTerms.pdiTerm' />
									</ToolTip>
									<FormattedMessage id='builderExpectations.contentSii' />{' '}
									<a
										href='https://www.tarion.com/sites/default/files/2023-03/Print%20Freehold%20Warranty%20Brochure%20-%20FINAL_0.pdf'
										aria-label={intl.formatMessage({
											id: 'builderExpectations.contentSiii'
										})}
									>
										<FormattedMessage id='builderExpectations.contentSiii' />
									</a>
									<FormattedMessage id='builderExpectations.contentSiv' />{' '}
									<a
										href='https://www.tarion.com/sites/default/files/2023-03/Print%20Condo%20Warranty%20Brochure%20-%20FINAL_0.pdf'
										aria-label={intl.formatMessage({
											id: 'builderExpectations.contentSv'
										})}
									>
										<FormattedMessage id='builderExpectations.contentSv' />
									</a>
									<FormattedMessage id='builderExpectations.contentSvi' />
									<br />
									<FormattedMessage id='builderExpectations.contentSvii' />{' '}
									<a href='mailto:complaints@hcraontario.ca'>complaints@hcraontario.ca</a>.{' '}
									<FormattedMessage id='builderExpectations.contentSviii' />{' '}
									<a href='https://www.tarion.com/'>Tarion</a>{' '}
									<FormattedMessage id='builderExpectations.contentSix' />
								</div>
							</li>
						</ol>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h2 id='act-professionally'>
							<span className='thin'>
								<FormattedMessage id='builderExpectations.headerMi' />
							</span>{' '}
							<FormattedMessage id='builderExpectations.headerMii' />
						</h2>
						<p>
							<FormattedMessage id='builderExpectations.contentTi' />{' '}
							<Link to='/licensing-compliance/directives-advisories/'>
								<FormattedMessage id='builderExpectations.contentTii' />
							</Link>
							.
						</p>

						<h3 className='sm'>
							<FormattedMessage id='builderExpectations.headerN' />
						</h3>
						<p>
							<FormattedMessage id='builderExpectations.contentU' />
						</p>

						<p>
							<FormattedMessage id='builderExpectations.contentVi' />{' '}
							<a href='../../../1. HCRA Advisory - Guide to Good Conduct v1.7 2021-07-09.pdf'>
								<FormattedMessage id='builderExpectations.contentVii' />
							</a>{' '}
							<FormattedMessage id='builderExpectations.contentViii' />
						</p>

						<ul>
							<li>
								<FormattedMessage id='builderExpectations.list02A' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02B' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02C' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02D' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02E' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02F' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02G' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02H' />
							</li>
							<li>
								<FormattedMessage id='builderExpectations.list02I' />
							</li>
						</ul>
					</Col>
				</Row>

				<Row>
					<Col lg={6} md={12}>
						<h2>
							<span className='thin'>
								<FormattedMessage id='builderExpectations.headerOi' />
							</span>{' '}
							<FormattedMessage id='builderExpectations.headerOii' />
						</h2>
						<p>
							<FormattedMessage id='builderExpectations.contentW' />
						</p>

						<h3 className='sm'>
							<FormattedMessage id='builderExpectations.headerP' />
						</h3>
						<p>
							<FormattedMessage id='builderExpectations.contentX' />
						</p>

						<h3 className='sm'>
							<FormattedMessage id='builderExpectations.headerQ' />
						</h3>
						<p>
							<FormattedMessage id='builderExpectations.contentY' />
						</p>
					</Col>

					<Col>
						<Img fluid={data.buildersImg.childImageSharp.fluid} alt='' />
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<Alert header={<FormattedMessage id='builderExpectations.headerR' />}>
							<p>
								<FormattedMessage id='builderExpectations.contentZi' />{' '}
								<a href='https://www.tarion.com/'>www.Tarion.com</a>{' '}
								<FormattedMessage id='builderExpectations.contentZii' />
							</p>
						</Alert>
					</Col>

					<Col md={12}>
						<Alert header={<FormattedMessage id='builderExpectations.headerS' />}>
							<p>
								<FormattedMessage id='builderExpectations.contentZiii' />{' '}
								<a href='https://www.tarion.com/'>www.Tarion.com</a>{' '}
								<FormattedMessage id='builderExpectations.contentZiiii' />
							</p>
						</Alert>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default WorkingWithYourBuilderPage;
